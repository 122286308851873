import {useEffect,useRef} from 'react';
import LogoS from '../../../assets/images/logoa1a.png'
import './index.scss'
import KUTE from 'kute.js'

const Logo = () => {
  const bgRef = useRef()
  const outlineLogoRef = useRef()
  const solidLogoRef = useRef()

  useEffect(() => {
      KUTE.to(bgRef.current, {duration: 1000, opacity: 1}).start()
      KUTE.fromTo(outlineLogoRef.current, { draw: "0% 0%"},{draw: "0% 100%"},{duration: 30000,}).start()
      KUTE.fromTo(solidLogoRef.current, {opacity: 0},{opacity: 1},{delay: 7000},{duration: 40000}).start()
  }, [])

  return (
    <div className="logo-container" ref={bgRef}>
      <img
        className="solid-logo"
        ref={solidLogoRef}
        src={LogoS}
        alt="JavaScript,  Developer"
      />

      <svg
        width="612pt"
        height="792pt"
        version="1.0"
        viewBox="0 0 612 792"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          className="svg-container"
          transform="translate(0 457) scale(.1 - .1)"
          fill="none"
        >
          <path
            ref={outlineLogoRef}
            d="M560.9,454.8V290.2c0-32.2-6.3-64.9-23.7-93.3
		c-1.2-2-4.4-0.7-3.8,1.6c3.4,15.2,5,30.6,5,46.1v164.6c0,37.2,2.4,73.3,9.8,102.9c0.3,1.3-0.7,2.6-2,2.6h-85
		c-1.3,0-2.3,1.2-2.1,2.5l6.8,41.4c0.2,1,1,1.8,2.1,1.8h100.9c1.4,0,2.4-1.3,2-2.6C563.4,528.1,560.9,491.7,560.9,454.8z
		 M432.4,459.2h-1.2c-0.5,0-1.1,0.2-1.4,0.6c-35.8,34.2-103.9,64.8-194.3,64.8c-78.4,0-133.4-23.2-164.6-55.5
		c-1.6-1.6-4.2-0.1-3.5,2.1c16.2,53.1,80.4,99,190.8,99c82.5,0,146.6-25.4,184.2-56c0.6-0.5,0.9-1.2,0.7-2l-8.5-51.2
		C434.3,460,433.4,459.2,432.4,459.2z M156.2,389.2c0,19.5,7.1,35.1,19,47c1.3,1.3,3.6,0.4,3.6-1.5c0,0,0,0,0-0.1
		c0-79.8,125.5-98.6,245.2-99.2c1.2,0,2.1-0.9,2.1-2.1v-41.2c0-1.2-0.9-2.1-2.1-2.1C298.3,288.5,156.2,304.1,156.2,389.2z
		 M278.7,123.7c-58.5,0-119.6,12.5-164.1,32c-1,0.5-2.2,0-2.7-1l-2.7-5.4l0,0l25.4,51c0.5,1,1.7,1.4,2.7,1
		c44.7-19.5,105.7-32,164.1-32c59.5,0,94.9,13.7,115.8,31.9c1.6,1.4,3.9-0.1,3.5-2.1C412.2,164.2,381.2,123.7,278.7,123.7z
    
    M455,504.8h-1.4c-0.6,0-1.1,0.2-1.5,0.6c-2.5,2.5-5.3,4.9-8.2,7.2
		c-0.6,0.5-0.9,1.2-0.8,1.9l0,0h12.6c1.3,0,2.3-1.1,2.1-2.4l-0.8-5.6C456.9,505.6,456,504.8,455,504.8z

    M538.2,409.2V244.6c0-19.6-2.4-39.3-8.1-58
		c-20.2-66.3-82.5-122-234.2-122c-79.3,0-154.7,15.2-207.4,38.2c-1.1,0.5-1.6,1.8-1,2.9l21.8,43.6l2.7,5.4c0.5,1,1.7,1.4,2.7,1
		c44.7-19.5,105.7-32,164.1-32c109,0,137.1,45.8,143.3,82c1.3,7.1,1.6,13.9,1.6,19.8v6.9c0,1.2-1,2.1-2.1,2.1
		C176.7,234,40.5,292.2,40.5,398.3c0,22.5,8.3,45,24.6,64.5c30,35.7,86.9,61.8,170.3,61.8c90.4,0,158.5-30.6,194.3-64.8
		c0.4-0.4,0.9-0.6,1.4-0.6h1.2c1,0,1.9,0.7,2.1,1.8l8.1,48.8c0.3,1.6,2.2,2.3,3.4,1.3c2.2-1.9,4.3-3.7,6.3-5.6
		c0.4-0.4,0.9-0.6,1.5-0.6h1.4c1,0,1.9,0.7,2.1,1.8l1,6.4c0.2,1,1,1.8,2.1,1.8h85.7c1.4,0,2.4-1.3,2-2.6
		C540.6,482.5,538.2,446.3,538.2,409.2z M156.2,389.2c0-85.1,141.9-100.7,268-99.1c1.2,0,2.1,1,2.1,2.1v74.2
		c0,8.2-2.6,17.3-6.6,25.5c-18.4,37.2-71.1,73.6-154.2,73.6c-34.7,0-66.3-8.4-86.3-25.9C164.8,427.5,156.2,410.7,156.2,389.2z
    "
          />
        </g>
      </svg>
    </div>
  )
}

export default Logo